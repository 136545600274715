.App {
  text-align: center;
}

.kuullogo{
  width: 20%;
  height: auto;
}

.coming-soon {
  background-image: url('./img/bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.p-tag{
  padding: 0 10px;
  opacity: 1;
}

@media screen and (max-width: 480px) {
  .kuullogo{
    width: 40%;
    height: auto;
  }
  .coming-soon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
   
  }
  .p-tag{
    width: 80%;
    
  }
}